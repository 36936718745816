<template>
  <div id="app">
    <div class="body fixed mobile">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <!-- <div class="spinner"><div><div></div></div></div> -->
      <!-- Header -->
      <Spinner :spin="spin" />
      <section class="header activity_question">
        <a class="icon iconButton third" @click.prevent="goBackTo">
          <font-awesome-icon
            v-if="!isQuestionnaire"
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
        <span class="fL">{{ title }}</span>
        <div class="icon iconButton" @click.prevent="goBackTo">
          <font-awesome-icon
            v-if="isQuestionnaire"
            icon="fa-solid fa-xmark"
            size="lg"
          ></font-awesome-icon>
        </div>
      </section>
      <!-- Main -->
      <section class="main activity_question">
        <div class="flex item-center gap-2" v-if="isQuestionnaire">
          <div style="color: var(--cG1)">
            問卷進度： {{ currentPage * pageSize }} /
            {{ qnaCampaign?.missions[0]?.settings?.questions.length }}
          </div>
          <ProgressBar
            class="flex-1"
            :progress="
              ((currentPage * pageSize) /
                qnaCampaign?.missions[0]?.settings?.questions.length) *
              100
            "
          />
        </div>
        <ValidationObserver
          v-if="!qnaCampaign.hasCompleted"
          v-slot="{ invalid }"
        >
          <ol class="bg-white">
            <div
              v-for="(question, index) in questions"
              :key="'question' + index"
            >
              <ValidationProvider slim :rules="getValidationRules(question)">
                <li class="fXXL primary" id="question-title">
                  {{ currentPage * pageSize + (index + 1) }}.
                  {{ question.title }}
                </li>
                <!-- <span class="fM gray" id="question-description">{{question.desc}}</span> -->
                <!-- <div 
                                    v-if="!valid"
                                    class="fS gray error"
                                >{{ showValidationRule(question) }}</div>  -->
                <div class="flexV w-full">
                  <label v-if="question.type === 'fill-text'">
                    <textarea
                      :name="'text_' + index"
                      v-model="question.fillinAnswer"
                      @change="
                        changeAnswer($event, {
                          ...question,
                          idx: isQuestionnaire
                            ? currentPage * pageSize + (index + 1)
                            : index,
                        })
                      "
                    />
                  </label>
                  <!-- Radio -->
                  <label
                    class="check gap-4"
                    :class="{
                      active:
                        question.fillinAnswer === option.value ||
                        (question.fillinAnswer &&
                          question.fillinAnswer.includes(option.value)),
                      correct:
                        submited &&
                        question.fillinAnswer === option.value &&
                        question.expectedAnswer === option.value &&
                        !question.answerChanged,
                      wrong:
                        submited &&
                        question.fillinAnswer === option.value &&
                        question.expectedAnswer !== option.value &&
                        !question.answerChanged,
                    }"
                    v-for="(option, indexOption) in question.options"
                    :key="'option' + indexOption"
                    @click="handleOptionClick(question, option)"
                  >
                    <input
                      v-if="question.type === 'multi-choice'"
                      type="checkbox"
                      :name="'checkbox_' + index"
                      :value="option.value"
                      v-model="question.fillinAnswer"
                      hidden
                      :disabled="checkCheckboxDisabled(question, option.value)"
                    />
                    <input
                      v-else-if="question.type === 'single-choice'"
                      type="radio"
                      :name="'radio_' + index"
                      hidden
                      :value="option.value"
                      v-model="question.fillinAnswer"
                      :disabled="checkCheckboxDisabled(question, option.value)"
                    />
                    <!-- checked -->
                    <!-- <span
                      v-if="question.type === 'multi-choice'"
                      class="checkmark checkbox"
                      :class="{
                        disabled: checkCheckboxDisabled(question, option.value),
                      }"
                    ></span>
                    <span v-else class="checkmark radio"></span> -->
                    <span class="text fL">{{ option.text }}</span>
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      style="width: 20px; height: 20px"
                      v-if="
                        submited &&
                        question.fillinAnswer === option.value &&
                        question.expectedAnswer === option.value &&
                        !question.answerChanged
                      "
                    />
                    <font-awesome-icon
                      :icon="['fas', 'xmark']"
                      style="width: 20px; height: 20px"
                      v-if="
                        submited &&
                        question.fillinAnswer === option.value &&
                        question.expectedAnswer !== option.value &&
                        !question.answerChanged
                      "
                    />
                  </label>
                </div>
              </ValidationProvider>
              <hr
                v-if="index !== questions.length - 1"
                class="my-3"
                style="border: 1px solid #eeeeee"
              />
            </div>
          </ol>

          <div class="flexH width middle">
            <a
              class="button rounded outline"
              v-if="currentPage > 0"
              @click.prevent="lastPage"
            >
              <span class="fM">上一部分</span>
            </a>
            <a
              v-if="isInitialized && !(!isQuestionnaire && remainTime < 0)"
              class="button rounded w-full"
              :class="{
                disabled:
                  isProcessing ||
                  invalid ||
                  (submited && questions.every((q) => !q.answerChanged)),
              }"
              @click.prevent="openSubmitPop()"
            >
              <span class="fM"
                >{{
                  (isQuestionnaire &&
                    totalPage - 1 > currentPage &&
                    "下一部分") ||
                  "送出"
                }}
                <span v-if="!isQuestionnaire"
                  >(還有 {{ remainTime + 1 }} 次機會)</span
                ></span
              >
            </a>
          </div>
        </ValidationObserver>
        <fragment v-else>
          <ul>
            <div
              v-for="(question, index) in questions"
              :key="'question' + index"
            >
              <li class="fXXL primary" id="question-title">
                {{ question.title }}
              </li>
              <span class="fM gray" id="question-description">{{
                question.desc
              }}</span>
              <div class="flexV">
                <!-- Radio -->
                <label
                  class="check"
                  v-for="(option, indexOption) in question.options"
                  :key="'option' + indexOption"
                >
                  <fragment v-if="question.type === 'multi-choice'">
                    <input
                      type="checkbox"
                      :name="'checkbox_' + index"
                      :value="option.value"
                      disabled
                      :checked="checkUserAnswer(question, option.value)"
                    />
                    <!-- checked -->
                    <span class="checkmark checkbox disabled"></span>
                  </fragment>
                  <fragment v-else>
                    <input
                      type="radio"
                      :name="'radio_' + index"
                      :value="option.value"
                      disabled
                      :checked="checkUserAnswer(question, option.value)"
                    />
                    <!-- checked -->
                    <span class="checkmark radio disabled"></span>
                  </fragment>
                  <span class="text fL">{{ option.text }}</span>
                </label>
              </div>
            </div>
          </ul>
          <div class="flexH width middle" v-if="publisher !== 'municipal'">
            <a
              class="button rounded"
              @click.prevent="$router.push('/campaign')"
            >
              <span class="fM">返回縣民任務</span>
            </a>
          </div>
        </fragment>
      </section>
      <!-- Popup (答案錯誤) -->
      <section id="popWrong" class="popup">
        <div class="popBody">
          <a class="close" @click.prevent="againButton()"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div class="icon">
              <font-awesome-icon
                v-if="remainTime < 0"
                :icon="['far', 'circle-xmark']"
                color="#eb5758"
                style="width: 60px; height: 60px"
              />
              <span class="fL" v-if="remainTime >= 0">答案錯誤</span>
            </div>
            <div class="text-center" v-if="remainTime < 0">通關失敗</div>
            <div class="fM text-center mt-2">
              {{
                remainTime >= 0
                  ? "答案不對欸～再試試看吧！"
                  : "很抱歉答案錯誤，感謝您的參與！"
              }}
            </div>
          </section>
          <section class="popFooter">
            <a class="button submit rounded" @click.prevent="clickWrongBtn()"
              ><span class="fM">確認</span></a
            >
          </section>
        </div>
      </section>
      <!-- Popup (答案正確) -->
      <section id="popCorrect" class="popCorrect popup">
        <div class="popBody">
          <a class="close" @click.prevent="closePopCorrect()"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <section class="popMain">
            <div class="icon">
              <font-awesome-icon
                :icon="['far', 'circle-check']"
                style="width: 60px; height: 60px"
              />
            </div>
            <div class="fL text-center">
              {{ isQuestionnaire ? "感謝您填寫問卷" : "通關成功" }}
            </div>
            <div class="fM text-center mt-2">
              {{
                isQuestionnaire
                  ? `恭喜您獲得雲林幣 ${qnaCampaign.reward_point} 枚！`
                  : `答案全對！您是民間高手，感謝您的作答，恭喜您獲得雲林幣 ${qnaCampaign.reward_point} 枚！`
              }}
            </div>
          </section>
          <section class="popFooter">
            <RouterLink
              to="/record"
              class="button rounded text-primary background border-button"
            >
              前往雲林幣錢包
            </RouterLink>
            <a class="button submit rounded" @click.prevent="closePopCorrect()"
              ><span class="fM">確認</span></a
            >
          </section>
        </div>
      </section>

      <section id="popNoReward" class="popup">
        <div class="popBody">
          <a class="close" @click.prevent="closePopNoReward"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div class="icon">
              <font-awesome-icon
                :icon="[
                  'far',
                  isQuestionnaire ? 'circle-check' : 'circle-xmark',
                ]"
                :color="isQuestionnaire ? '#9748ff' : '#eb5758'"
                style="width: 60px; height: 60px"
              />
            </div>
            <div class="fL text-center">
              {{ isQuestionnaire ? "感謝您填寫問卷" : "雲林幣已搶完" }}
            </div>
            <div class="fM text-center mt-2">
              {{
                isQuestionnaire
                  ? "很可惜雲林幣已搶完，但我們仍非常感謝您熱情參與！"
                  : "很抱歉雲林幣已搶完，謝謝您的參與！"
              }}
            </div>
          </section>
          <section class="popFooter">
            <a
              class="button submit rounded mx-auto"
              style="width: 200px"
              @click.prevent="closePopNoReward"
              ><span class="fM">確認</span></a
            >
          </section>
        </div>
      </section>

      <section id="popLeave" class="popup">
        <div class="popBody">
          <a class="close" @click.prevent="closeLeavePop()"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div class="fL text-center">提示</div>
            <div class="text-center mt-2">您確定要中途離開嗎？</div>
          </section>
          <section class="popFooter">
            <a
              class="button rounded text-primary background border-button"
              @click.prevent="closeLeavePop()"
              >返回</a
            >

            <a class="button submit rounded" @click.prevent="$router.go(-1)"
              ><span class="fM">確認</span></a
            >
          </section>
        </div>
      </section>

      <section id="popSubmit" class="popup">
        <div class="popBody">
          <a class="close" @click.prevent="closeSubmitPop()"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div class="fL text-center">提示</div>
            <div class="icon"></div>
            <div class="text-center">
              送出就會扣除一次機會，請問您確定要送出了嗎？
            </div>
          </section>
          <section class="popFooter">
            <a
              class="button rounded text-primary background border-button"
              @click.prevent="closeSubmitPop()"
              >再想想</a
            >

            <a class="button submit rounded" @click.prevent="submit()"
              ><span class="fM">送出</span></a
            >
          </section>
        </div>
      </section>

      <section id="popUnexpectError" class="popup">
        <div class="popBody">
          <a class="close" @click.prevent="againButton()"
            ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
          ></a>
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div class="icon">
              <font-awesome-icon
                :icon="['far', 'circle-xmark']"
                color="#eb5758"
                style="width: 60px; height: 60px"
              />
            </div>
            <div class="fM text-center mt-2">
              未預期的錯誤，請稍後再試！
            </div>
          </section>
          <section class="popFooter">
            <a class="button submit rounded" @click.prevent="goBackList()"
              ><span class="fM">確認</span></a
            >
          </section>
        </div>
      </section>
    </div>
    <AlertDialog
      v-if="showAlert"
      :title="alert.title"
      :content="alert.message"
      @close="onCloseAlertDialog"
    />
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import Spinner from "@/components/Spinner";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ProgressBar from "@/components/ProgressBar.vue";
import AlertDialog from '@/components/Dialog/AlertDialog.vue'

export default {
  name: "QA",
  components: {
    Spinner,
    ValidationObserver,
    ValidationProvider,
    ProgressBar,
    AlertDialog
  },
  data() {
    return {
      currentPage: 0,
      pageSize: 10,
      submited: false,
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      questionInfo: {
        questions: [],
      },
      showAlert: false,
      alert: {
        title: '',
        message: ''
      },
      answer: "",
      prize: 10,
      recivePoints: 0,
      getCampaignInfoUrl: "",
      campaignSubmitUrl: "",
      qtnContext: {},
      answers: [],
      campaigns: [],
      countUnread: 0,
      isParticipatedQA: false,
      qnaCampaign: {},
      qnaCampaignId: "",
      spin: true,
      isProcessing: false,
      isInitialized: false,
      publisher: this.$route.meta.publisher,
      remainTime: 3,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //     // called before the route that renders this component is confirmed.
  //     // does NOT have access to `this` component instance,
  //     // because it has not been created yet when this guard is called!
  //     console.log(to, from);
  //     next();
  // },
  created() {
    let vm = this;
    let that = vm;
    let campaignId = this.$route.params.id;

    // if (!(that.user && that.user.userId)) {
    //     this.$router.push("/error");
    // } else {
    that
      .getCampaigns()
      .then(() => {
        that.qnaCampaign = that.campaigns.find(
          (campaign) => campaign.campaignId == campaignId
        );
        that.qnaCampaign.missions.forEach((mission) => {
          mission.settings.questions.forEach((q) => {
            q.fillinAnswer = [];
          });
        });
        console.log("qnaCampaign: ", that.qnaCampaign);
        // if (
        //     that.qnaCampaign &&
        //     that.checkIsParticipatedQA(that.qnaCampaign)
        // ) {
        //     this.$store.commit("updateIsFinishQuestions", true);
        //     this.$router.push(`/home`);
        //     return;
        // }
        if (!that.qnaCampaign || Object.keys(that.qnaCampaign).length == 0) {
          // this.$store.commit("updateIsQANotExist", true);
          this.$router.push(`/error`);
          return;
        }
        that.spin = true;
        that
          .campaignStart(campaignId)
          .then((res) => {
            that.getCampaignInfoUrl = res.getInfoUrl;
            that.campaignSubmitUrl = res.submit_url;
            that.qtnContext = res.context;
            that
              .getQuestions()
              .then((getQuestionsRes) => {
                Object.assign(that.questionInfo, getQuestionsRes);
                that.spin = false;
                that.isInitialized = true;
              })
              .catch((err) => {
                console.log("err: ", err);
                this.$router.push("/error");
              });
          })
          .catch((err) => {
            console.log("err: ", err);
            this.$router.push("/error");
          });
      })
      .catch((getCampaignsErr) => {
        console.log("getCampaignsErr: ", getCampaignsErr);
        this.$router.push("/error");
      });
    // }
  },
  mounted() {
    this.remainTime = this.checkAnswerTime();
  },
  computed: {
    ...mapState(["user", "userInfo", "isQANotExist"]),
    ...mapGetters({
      userPoints: "getTyUserPoints",
    }),
    isQuestionnaire() {
      return this.qnaCampaign.campaignPrototype === "questionnaire";
    },
    questions() {
      return (
        this.qnaCampaign?.missions &&
        (this.isQuestionnaire
          ? this.qnaCampaign?.missions[0]?.settings?.questions.slice(
              this.currentPage * this.pageSize,
              (this.currentPage + 1) * this.pageSize
            )
          : this.qnaCampaign?.missions[0]?.settings?.questions)
      );
    },
    totalPage() {
      return (
        this.qnaCampaign?.missions[0]?.settings?.questions.length /
        this.pageSize
      );
    },
    rewardCompleted() {
      return (
        this.qnaCampaign &&
        this.qnaCampaign.campaignBudget &&
        this.qnaCampaign.campaignBudget.availablePoints <= 0
      );
    },
    title() {
      return this.isQuestionnaire ? "問卷調查" : "問答拿獎勵";
    },
  },
  methods: {
    lastPage() {
      this.currentPage--;
      window.scrollTo(0, 0);
    },
    clickWrongBtn() {
      if (this.remainTime < 0) {
        this.$router.go(-2);
      }
      this.againButton();
    },
    goBackList() {
      window.history.length > 2 ? this.$router.go(-2) : this.$router.push("/home");
    },
    checkAnswerTime() {
      let answerTimes = localStorage.getItem("answerTimes");
      if (!answerTimes) {
        answerTimes = JSON.stringify([
          { campaignId: this.$route.params.id, remainTime: 3 },
        ]);
        localStorage.setItem("answerTimes", answerTimes);
      }
      console.log("qwe", answerTimes);
      return (
        answerTimes &&
        JSON.parse(answerTimes).find(
          (t) => t.campaignId == this.$route.params.id
        )?.remainTime
      );
    },
    isOverToday(campaign) {
      const today = moment();
      const endTime = moment(campaign.campaignEndTime * 1000);
      return endTime.isBefore(today);
    },
    onCloseAlertDialog() {
      this.showAlert = false;
      this.alert = {
        title: '',
        message: ''
      }
      this.$router.replace("/service");
    },
    openSubmitPop() {
      if (this.isOverToday(this.qnaCampaign)) {
        this.alert = {
          title: '提醒',
          message: '任務已下架'
        }
        this.showAlert = true
        return;
      }

      if (this.isQuestionnaire) {
        if (this.currentPage < this.totalPage - 1) {
          window.scrollTo(0, 0);
          return this.currentPage++;
        }
        return this.submit();
      }
      const popSubmit = document.getElementById("popSubmit");
      popSubmit.style.display = "flex";
    },
    closeSubmitPop() {
      const popSubmit = document.getElementById("popSubmit");
      popSubmit.style.display = "none";
    },
    openLeavePop() {
      const popLeave = document.getElementById("popLeave");
      popLeave.style.display = "flex";
    },
    closeLeavePop() {
      const popLeave = document.getElementById("popLeave");
      popLeave.style.display = "none";
    },
    handleOptionClick(question, option) {
      if (question.fillinAnswer !== question.expectedAnswer) {
        question.answerChanged = true;
      }
      if (question.type === "multi-choice") {
        this.answers.push(option);
        if (!question.fillinAnswer) {
          question.fillinAnswer = [];
        }
        const idx = question.fillinAnswer.indexOf(option);
        if (idx > -1) {
          question.fillinAnswer.splice(idx, 1);
        } else {
          question.fillinAnswer.push(option);
        }
      }
    },
    dipslayUserPointBalance() {
      return this.userPoints.pointBalance;
    },
    changeAnswer(_, question) {
      const questionId = question.id;
      this.answers[questionId] = this.questions.find(
        (q) => q.id == questionId
      )?.fillinAnswer;
      this.answers = { ...this.answers };
    },
    tsToDate(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD");
    },
    dummyGetCampaigns() {
      let result = {
        uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
        memberId: 208,
        campaigns: [
          {
            campaignId: "M_RdROw7eY5B",
            campaignName: "測試幸運輪盤任務",
            campaignDesc: "輪盤抽獎。Yay!!",
            campaignStatus: "approved",
            campaignPrototype: "minigame",
            campaignStartTime: 1652284800,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652322816,
            missions: [
              {
                missionId: 44,
                missionName: "幸運輪盤（小遊戲任務）",
                missionDesc: "As title",
                participation: [
                  {
                    data: {
                      rewardIds: ["38"],
                    },
                    status: "completed",
                    completedAt: 1652341991,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_r5PDKD0m58",
            campaignName: "PrePOB測試任務：抽獎小遊戲",
            campaignDesc: "As title",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1652112000,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652167000,
            missions: [
              {
                missionId: 36,
                missionName: "抽獎小遊戲",
                missionDesc: "好禮大方送",
                participation: [
                  {
                    data: {
                      rewardIds: ["31"],
                    },
                    status: "completed",
                    completedAt: 1652170978,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_xd4wXAwvkj",
            campaignName: "PrePOB測試任務：推薦",
            campaignDesc:
              "To recommend or not to recommend, that is the question.",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1652025600,
            campaignEndTime: 1656604799,
            campaignCreatedAt: 1652070487,
            missions: [
              {
                missionId: 31,
                missionName: "測試推薦任務 之 推薦人關卡",
                missionDesc: "推薦他人成功可以領取獎項",
                participation: [
                  {
                    status: "completed",
                    completedAt: 1652092171,
                  },
                  {
                    status: "completed",
                    completedAt: 1652092477,
                  },
                ],
              },
              {
                missionId: 32,
                missionName: "推薦人關卡",
                missionDesc: "推薦他人成功可以領取獎項",
              },
              {
                missionId: 34,
                missionName: "被推薦人關卡",
                missionDesc: "接受他人推薦連結並成功註冊者可以領取獎項",
              },
              {
                missionId: 35,
                missionName: "註冊關卡",
                missionDesc: "成功註冊者可以領取獎項",
              },
            ],
          },
          {
            campaignId: "M_p5O76qR854",
            campaignName: "註冊任務",
            campaignDesc: "成功註冊即可獲得100點",
            campaignStatus: "approved",
            campaignPrototype: null,
            campaignStartTime: 1651132800,
            campaignEndTime: 1656575999,
            campaignCreatedAt: 1651129632,
            missions: [
              {
                missionId: 22,
                missionName: "會員註冊任務",
                missionDesc: "只要成功註冊會員即可獲得，每人限領一次",
                participation: [
                  {
                    data: {},
                    status: "completed",
                    completedAt: 1652253394,
                  },
                ],
              },
            ],
          },
          {
            campaignId: "M_qkwQ9ygK5L",
            campaignName: "2022問答任務debug測試",
            campaignDesc: "答對有獎，限量三名",
            campaignStatus: "approved",
            campaignPrototype: "test",
            campaignStartTime: 1650816000,
            campaignEndTime: 1656575999,
            campaignCreatedAt: 1650862201,
            missions: [
              {
                missionId: 16,
                missionName: "問答任務 測試",
                missionDesc: "有獎問答 測試",
                participation: [
                  {
                    status: "completed",
                    completedAt: 1651387810,
                  },
                ],
              },
            ],
          },
        ],
      };
      let promise = new Promise((resolve) => {
        resolve({ data: result });
      });
      return promise;
    },
    getCampaignAPI() {
      const param = `&publisherType=${
        this.publisher === "municipal" ? "municipal" : "exclusive"
      }`;
      let config = {
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true${param}`,
        method: "GET",
      };
      return this.$http(config);
    },
    getCampaigns() {
      return (
        this.getCampaignAPI()
          .then((res) => {
            this.campaigns = res.data.campaigns;
            console.log("campaigns: ", this.campaigns);
            return res;
          })
      );
    },
    campaignStart(campaignId) {
      return fetch(
        `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${campaignId}/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}/run`,
        { method: "GET" }
      )
        .then(function (response) {
          return response.json();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getQuestions() {
      let config = {
        url: this.getCampaignInfoUrl,
        method: "GET",
        params: {
          uuid: this.user.userId,
        },
      };
      return this.$http(config)
        .then((res) => {
          console.log("questions: ", res);
          return res.data;
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    },
    dummyWrongAnswer() {
      let result = {
        success: false,
        evaluation: {
          corrects: [],
          incorrects: [
            {
              id: 10,
              prompted: "A",
              expected: "C",
            },
          ],
          score: 0,
          totalScore: 1,
          passingScore: 1,
          hasPassed: false,
          correctness: 0,
        },
      };
      let promise = new Promise((resolve) => {
        resolve({ data: result });
      });
      return promise;
    },
    dummyCorrectAnswer() {
      let result = {
        success: true,
        evaluation: {
          corrects: [
            {
              id: 10,
              prompted: "C",
              expected: "C",
            },
          ],
          incorrects: [],
          score: 1,
          totalScore: 1,
          passingScore: 1,
          hasPassed: true,
          correctness: 1,
        },
        claimRewardResults: [
          {
            merchantId: "tycard2022",
            campaignId: "M_PkJYrY4mbM",
            missionId: 14,
            memberId: 208,
            uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
            requests: {
              12: false,
            },
            rewardId: 12,
            rewardName: "問答任務完成獎品100點",
            campaignBudgetId: 16,
            context: {
              name: "點數",
              amount: 100,
            },
            status: true,
            data: {
              points: 100,
              transactionId: "47f28c3ac01dfbbf535c6bb58f89cf96c7ce4a3d",
              transactionStatus: "success",
              transactionType: "Activity-Reward",
            },
          },
        ],
      };
      let promise = new Promise((resolve) => {
        resolve({ data: result });
      });
      return promise;
    },
    dummyNoReward() {
      let result = {
        success: true,
        evaluation: {
          corrects: [
            {
              id: 10,
              prompted: "C",
              expected: "C",
            },
          ],
          incorrects: [],
          score: 1,
          totalScore: 1,
          passingScore: 1,
          hasPassed: true,
          correctness: 1,
        },
        claimRewardResults: [
          {
            status: false,
            rewardId: 12,
            rewardName: "問答任務完成獎品100點",
            campaignBudgetId: 16,
            context: {
              name: "點數",
              amount: 100,
            },
            error: {
              code: "RW105",
              message: "獎項不允許重複領取",
            },
          },
        ],
      };
      let promise = new Promise((resolve) => {
        resolve({ data: result });
      });
      return promise;
    },
    async submit() {
      if (this.isProcessing === true) return;
      this.isProcessing = true;
      this.submited = true;
      const questions =
        this.qnaCampaign?.missions &&
        this.qnaCampaign?.missions[0]?.settings?.questions;
      questions.forEach((q) => {
        q.answerChanged = false;
      });
      this.isProcessing = false;
      const isCorrect =
        questions.every((q) => q.fillinAnswer === q.expectedAnswer) ||
        this.isQuestionnaire;
      if (isCorrect) {
        // this.correctAction();
      } else if (!isCorrect && this.remainTime > 0) {
        this.wrongAction();
      }
      this.closeSubmitPop();
      if (this.qnaCampaign.campaignPrototype === "qna" && !isCorrect) {
        let answerTimes = localStorage.getItem("answerTimes");
        if (!answerTimes) {
          answerTimes = `[
              { "campaignId": ${this.qnaCampaign.campaignId}, "remainTime": 3 },
            ]`;
          localStorage.setItem("answerTimes", JSON.stringify(answerTimes));
          this.remainTime = 3;
        } else {
          answerTimes = JSON.parse(answerTimes);
          const answerTime = answerTimes.find(
            (t) => t.campaignId == this.qnaCampaign.campaignId
          );
          if (!answerTime) {
            answerTimes.push({
              campaignId: this.qnaCampaign.campaignId,
              remainTime: 2,
            });
          }
          if (answerTime.remainTime >= 0) {
            answerTime.remainTime--;
          }
          localStorage.setItem("answerTimes", JSON.stringify(answerTimes));
          this.remainTime = answerTime.remainTime;
        }
        if (this.remainTime >= 0) return;
      }
      let answers = {};
      questions.forEach((q, idx) => {
        answers[`key${idx + 1}`] = q.fillinAnswer;
      });

      const data = JSON.stringify({
        uuid: this.user.userId,
        member_id: this.userInfo.memberId,
        nonce: new Date().getTime(),
        data: answers,
        // answers: this.answers,
        // evaluateImmediately: true,
        // context: this.qtnContext
      });
      try {
        const res = await this.$http({
          url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/member/missions/${this.qnaCampaign.missions[0].missionId}/complete`,
          method: "POST",
          data,
        });
        const completeMap = {
          completed: this.correctAction,
          "completed-qty-not-enough": this.noRewardAction,
          "completed-no-reward": this.wrongAction,
        };
        const { completeness } = res.data.mission;
        if (completeMap[completeness]) {
          completeMap[completeness]();
        }
      } catch(e) {
        const popUnexpectError = document.getElementById('popUnexpectError')
        popUnexpectError.style.display = 'flex'
      }
      // let answers = {}
      // questions.forEach((q, idx) => {
      //     answers[`key${idx + 1}`] = q.fillinAnswer
      // })

      // const data = JSON.stringify({
      //     uuid: this.user.userId,
      //     member_id: this.userInfo.memberId,
      //     nonce: 22222,
      //     data: answers
      //     // answers: this.answers,
      //     // evaluateImmediately: true,
      //     // context: this.qtnContext
      // });

      // const config = {
      //     url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/member/missions/${this.qnaCampaign.missions[0].missionId}/complete`,
      //     method: "POST",
      //     headers: {
      //         "Content-Type": "application/json"
      //     },
      //     data: data
      // };

      // // return this.dummyNoReward(config)
      // const self = this;
      // return this.$http(config)
      //     .then(
      //         function (response) {
      //             console.log("submit: ", response.data);
      //             this.closeSubmitPop()
      //             const isCorrect = questions.every(q => q.fillinAnswer === q.expectedAnswer) || this.$route.fullPath.includes('b1')
      //             if (isCorrect) {
      //                 this.parseRewards(response.data);
      //                 console.log("Correct!");
      //                 this.getUserPoints()
      //                     .then((getUserPointsRes) => {
      //                         this.$store.commit(
      //                             "updateUserPoints",
      //                             getUserPointsRes
      //                         );
      //                         this.doActionByClaimRewardType(
      //                             response.data
      //                         );
      //                     })
      //                     .catch((err) => {
      //                         console.log("err: ", err);
      //                         this.errorHandler();
      //                     });
      //             } else {
      //                 console.log("Wrong!");
      //                 this.wrongAction();
      //                 this.isProcessing = false;
      //             }
      //             return response.data;
      //         }.bind(this)
      //     )
      //     .catch(function (error) {
      //         console.log('eerrrr');
      //         const code = error.response.data.code;
      //         if (code == 'QTN101') { //已成功作答過
      //             self.noRewardAction();
      //         }
      //         else if (code == 'QTN102') { //答案有誤
      //             self.wrongAction();
      //             self.isProcessing = false;
      //         }
      //         // self.errorHandler();
      //     })
    },
    doActionByClaimRewardType(responseData = {}) {
      let reward =
        responseData.claimRewardResults &&
        responseData.claimRewardResults.length > 0
          ? responseData.claimRewardResults[0]
          : [];
      let status = reward.status;
      if (status) {
        this.correctAction();
      } else {
        this.noRewardAction();
      }
    },
    correctAction() {
      const popCorrect = document.getElementById("popCorrect");
      popCorrect.style.display = "flex";
    },
    closePopCorrect() {
      const popCorrect = document.getElementById("popCorrect");
      popCorrect.style.display = "none";
      const backURL = this.isQuestionnaire ? "/B1" : "/service/questions";
      window.history.length >= 2
        ? this.$router.go(-2)
        : this.$router.push(backURL);
    },
    wrongAction() {
      const popWrong = document.getElementById("popWrong");
      popWrong.style.display = "flex";
    },
    noRewardAction() {
      const popWrong = document.getElementById("popNoReward");
      popWrong.style.display = "flex";
    },
    closePopNoReward() {
      const popWrong = document.getElementById("popNoReward");
      popWrong.style.display = "none";
      this.$router.go(-2);
    },
    againButton() {
      const popWrong = document.getElementById("popWrong");
      popWrong.style.display = "none";
    },

    getUserPoints() {
      const config = {
        method: "get",
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    parseRewards(responseData = {}) {
      let reward =
        responseData.claimRewardResults &&
        responseData.claimRewardResults.length > 0
          ? responseData.claimRewardResults[0]
          : [];
      let status = reward.status;
      if (status) {
        this.recivePoints =
          reward.data && reward.data.points ? reward.data.points : 0;
      } else {
        this.recivePoints = 0;
      }
    },
    checkIsCorrect(responseData = {}) {
      let hasPassed = responseData?.evaluation?.hasPassed;
      return hasPassed;
    },
    checkIsParticipatedQA(campaign) {
      let mission =
        campaign.missions && campaign.missions.length > 0
          ? campaign.missions[0]
          : {};
      if (Object.keys(mission).length > 0) {
        let participation = mission.participation
          ? mission.participation[0]
          : {};
        if (participation.status === "completed") {
          return true;
        }
      }
      return false;
    },
    errorHandler() {
      window.location.reload();
    },
    goBackTo() {
      this.openLeavePop();
      // this.publisher == 'municipal'
      //     ? this.$router.push("/service/questions")
      //     : this.$router.push("/campaign");
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
    getValidationRules() {
      return "required";
    },
    showValidationRule() {
      return "(必填)";
    },
    checkCheckboxDisabled(question, optionValue) {
      const { type, fillinAnswer, maxSelection } = question;
      if (type !== "multi-choice" || !maxSelection)
        return (
          this.submited &&
          fillinAnswer === question.expectedAnswer &&
          !question.answerChanged
        );
      return (
        fillinAnswer.length >= maxSelection &&
        fillinAnswer.indexOf(optionValue) === -1
      );
    },
    checkUserAnswer(question, optionValue) {
      let checked = false;
      if (question.userAnswers) {
        for (let i = 0, len = question.userAnswers.length; i < len; i++) {
          if (question.userAnswers[i].answer === optionValue) {
            checked = true;
            break;
          }
        }
      }
      return checked;
    },
  },
};
</script>
<style lang="scss">
ol {
  padding: 0 !important;
}
li::marker {
  content: "";
}
.check {
  background: #f6f2fd;
  color: 2b2455;
  margin: 10px 0;
  padding: 15px !important;
  &.active {
    background: #a480ff !important;
    span {
      color: #fff !important;
    }
  }
  &.correct {
    background: #ffc402 !important;
  }
  &.wrong {
    background: #bdbdbd !important;
  }
}
</style>
<style lang="scss" scoped>
.body {
  .activity_question.main {
    background: transparent;
    padding: 0px !important;
    border-radius: 0;
    ol {
      padding: 16px !important;
      border-radius: 20px;
    }
    .button {
      &.outline {
        background-color: #fff;
        border: 1px solid var(--k1);
        span {
          color: var(--k1) !important;
        }
      }
    }
  }
}
#question-title {
  margin-top: 20px;
}
</style>
